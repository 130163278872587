import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import Decimal from 'decimal.js';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      active: 0,
      lang_list: {},
      select_lang: {},
      show: false,
      buy: {},
      lang: {},
      cate: [],
      user: {},
      url: "",
      address: "",
      count: 0,
      app_root: api.base(),
      list: [],
      loading: false,
      finished: false,
      page: 1,
      pz2: {},
      pz4: {
        value: 0
      },
      pz5: {},
      num: 0,
      num1: 0,
      hui0: "",
      hui1: "",
      zfpass: "",
      list1: [],
      loading1: false,
      finished1: false,
      page1: 1,
      list2: [],
      loading2: false,
      finished2: false,
      page2: 1,
      showPicker: false,
      columns: [],
      showPicker1: false,
      columns1: [],
      hui: {
        value: 0.55
      },
      type: {},
      buy: [],
      shell: [],
      shou: 0
    };
  },
  computed: {
    result() {
      if (this.num) {
        let num1 = new Decimal(this.pz4.value);
        let num2 = new Decimal(this.num);
        return num1.times(num2).toFixed(4);
      }
    },
    result1() {
      if (this.num1 > 0) {
        let num1 = new Decimal(this.buy.price);
        let num2 = new Decimal(this.num1);
        return num1.times(num2).toFixed(4);
      }
    }
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    onConfirm(event) {
      console.log(event.selectedOptions[0]);
      this.hui = event.selectedOptions[0];
      this.showPicker = false;
    },
    onConfirm1(event) {
      this.type = event.selectedOptions[0];
      console.log(this.type.value);
      this.showPicker1 = false;
      if (this.type.value == 0) {
        this.columns = this.shell;
        this.hui = this.shell[0];
      }
      if (this.type.value == 1) {
        this.columns = this.buy;
      }
    },
    onLoad() {
      this.loading = true;
      api.all('/api/index/otc_list', {
        page: this.page
      }, (err, data) => {
        this.loading = false;
        if (!err && data.code === 1) {
          this.page = this.page++;
          for (let i = 0; i < data.data.list.length; i++) {
            this.list.push(data.data.list[i]);
          }
          if (this.list.length >= data.data.total) {
            this.finished = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    onLoad1() {
      this.loading1 = true;
      api.all('/api/index/my_otc_list', {
        page: this.page1
      }, (err, data) => {
        this.loading1 = false;
        if (!err && data.code === 1) {
          this.page1 = this.page1++;
          for (let i = 0; i < data.data.list.length; i++) {
            this.list1.push(data.data.list[i]);
          }
          if (this.list1.length >= data.data.total) {
            this.finished1 = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    onLoad2() {
      this.loading2 = true;
      api.all('/api/index/otc_buy_list', {
        page: this.page2
      }, (err, data) => {
        this.loading2 = false;
        if (!err && data.code === 1) {
          this.page2 = this.page2++;
          for (let i = 0; i < data.data.list.length; i++) {
            this.list2.push(data.data.list[i]);
          }
          if (this.list2.length >= data.data.total) {
            this.finished2 = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    get_imdex_data: function () {
      api.all('/api/index/otc', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          this.pz4 = data.data.pz4;
          this.columns = data.data.arr1;
          this.shell = data.data.arr1;
          this.buy = data.data.arr;
          this.columns1 = data.data.type;
          this.hui = data.data.pz4;
          this.type = data.data.type[0];
          this.shou = data.data.shou;
        } else {
          console.log(data);
        }
      });
    },
    buy_in: function () {
      api.all('/api/index/jiao', {
        num: this.num,
        pass: this.zfpass
      }, (err, data) => {
        if (!err && data.code === 1) {
          showSuccessToast(data.msg);
          setTimeout(() => {
            this.get_imdex_data();
            this.page1 = 1;
            this.list1 = [];
            this.onLoad1();
          }, 1500);
        } else if (!err && data.code === 0) {
          showToast(data.msg);
        } else {
          console.log(data);
        }
      });
    },
    otc_buy: function (id1) {
      api.all('/api/index/otc_in', {
        id: this.buy.id,
        num: this.num1,
        pass: this.zfpass
      }, (err, data) => {
        if (!err && data.code === 1) {
          showSuccessToast(data.msg);
          setTimeout(() => {
            this.show = false;
            this.num1 = 0;
            this.buy = {};
            this.get_imdex_data();
            this.page1 = 1;
            this.list1 = [];
            this.onLoad1();
          }, 1500);
        } else if (!err && data.code === 0) {
          showToast(data.msg);
        } else {
          console.log(data);
        }
      });
    },
    otc_in: function (item) {
      // api.all('/api/index/otc_in', { id:id}, (err, data) => {
      //   if (!err && data.code === 1) {
      //     showSuccessToast(data.msg)
      //     setTimeout(() => { 
      //       this.get_imdex_data() 
      //       this.page=1;
      //       this.list=[];
      //       this.onLoad()
      //     }, 2500)
      //   } else if (!err && data.code === 0) {
      //     showToast(data.msg)
      //   } else {
      //     console.log(data);

      //   }
      // });
      this.buy = item;
      this.show = true;
    }
  }
};